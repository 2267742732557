import React, { Component } from 'react'
import './report-page.less';
import { withRoutePane } from '@/components/hoc/routePane';
import { RProps } from '@/config/props';
import { report, requestReport } from '@/interface/report';
import { ProjectContext } from '@/config/context';
import { Row, Col, Button, Upload, Table, Modal, Form, Space, message, Popconfirm, Select, Input, Spin, Progress } from 'antd';
import { docUploadUrl, downloadUrlPrefix } from '@/config/api/document';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import { DatePicker } from '@/components';
import { queryReports, createReport, deleteReport, generateReports, queryReportsGenerating, downloadReport } from '@/config/api/report';
import dayjs from 'dayjs';
import axios from '@/config/axios';
import { constage } from '@/interface/constage';
import { FormInstance } from 'antd/lib/form';
import { queryConstages } from '@/config/api/constage';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;
export declare type ReportType = 'stage' | 'final' | 'scheme';

interface IState {
    reports: report[],
    requestReports: requestReport[],
    modalVisible: boolean,
    reportGenType: ReportType,

    constages: constage[],

    uploading: boolean
    loading: boolean
    downloadLoading: boolean
    onGenerateReportLoading: boolean
}
class ReportPage extends Component<RProps, IState> {
    static contextType = ProjectContext;
    constageReportFormRef = React.createRef<FormInstance>();
    timer$: any;

    state = {
        reports: [],
        requestReports: [],
        modalVisible: false,
        reportGenType: null,

        constages: [],

        uploading: false,
        loading: false,
        downloadLoading: false,
        onGenerateReportLoading: false,
    }

    componentDidMount() {
        this.findReports();
        this.findConstages();
        this.findRequestReports();
        this.timer$ = setInterval(
            () => this.findRequestReports(),
            1000 * 2,
        )
    }

    componentWillUnmount() {
        clearInterval(this.timer$);
    }

    findRequestReports = () => {
        const projectId = this.context;
        let stagePromise = queryReportsGenerating(projectId, 2);
        let finalPromise = queryReportsGenerating(projectId, 3);
        axios.all([stagePromise, finalPromise])
            .then(res => {
                let r1 = res[0].data || [];
                let r2 = res[1].data || [];
                let rr = [...r1, ...r2].filter(item => item.status === 0)
                this.setState({
                    requestReports: rr
                })
            })
        this.findReports(false);
    }

    findConstages = () => {
        const projectId = this.context;
        queryConstages(projectId)
            .then(res => {
                let constages = res.data || [];
                constages = constages.sort((a, b) => dayjs(a.start_time).valueOf() - dayjs(b.start_time).valueOf());
                this.setState({
                    constages: constages
                })
            })
    }

    findReports = (loading: boolean = true) => {
        const projectId = this.context;
        this.setState({
            loading: loading,
        })
        queryReports(projectId)
            .then(res => {
                let reports = res.data || [];
                this.setState({
                    reports: reports,
                })
                // console.log(reports)
            }).finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    // generateReport = (values) => {
    //     console.log(values);
    //     this.setState({
    //         modalVisible: false,
    //         reportGenType: null,
    //     })
    // }

    handleChange = (info) => {
        console.log(info.file.status);
        if (info.file.status !== 'uploading') {
            this.setState({
                uploading: false
            })
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            // if (this.props.afterUpload) {
            //     this.props.afterUpload(info.file.response.data.url);
            // }
            console.log(info.file);
            let data = info.file.response.data;
            this.uploadReport(data);
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    uploadReport = (values) => {
        const projectId = this.context;
        let data = {
            ...values,
            id: null,
            project_id: projectId,
            type: 1,
        }
        createReport(projectId, data)
            .then(res => {
                this.findReports();
            })
    }

    onDelete = (record: report) => {
        deleteReport(this.context, record.id)
            .then(res => {
                this.findReports();
            })
    }

    donwloadFile = (record: report) => {
        // const projectId = this.context;
        // this.setState({
        //     downloadLoading: true,
        // })
        // downloadReport(projectId, record.id)
        // .then(res => {
        //     const blob = new Blob([res.data]);
        //     const link = document.createElement('a');
        //     let contentDisposition = res.headers['content-disposition'];  
        //     let filename = /filename="(.*)"/.exec(contentDisposition)[1] || '报告.docx';
        //     link.setAttribute('href', window.URL.createObjectURL(blob));
        //     link.setAttribute('download', filename);
        //     link.style.visibility = 'hidden';
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // }).catch(err => {
        //     console.log(err);
        //     message.error('下载失败');
        // }).finally(() => {
        //     this.setState({
        //         downloadLoading: false,
        //     })
        // })
        if (process.env.NODE_ENV === 'development') {
            const projectId = this.context;
            downloadReport(projectId, record.id).then(res => {
                if (res.status === 200) {
                    const url = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = record.name;
                    link.click();
                    URL.revokeObjectURL(url);
                }
            })
        } else {
            const link = document.createElement('a');
            link.setAttribute('href', `/api${record.url}`);
            link.setAttribute('download', record.name);
            link.setAttribute('target', '_blank');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    }

    onConstageSelectChange = (value: number) => {
        if (!this.constageReportFormRef.current) return;
        let stage: constage = this.state.constages.find(item => item.id === value);
        if (!stage) {
            message.warning('找不到阶段');
            return;
        }
        this.constageReportFormRef.current?.setFieldsValue({
            user_start_time: dayjs(stage?.start_time),
            user_end_time: dayjs(stage?.end_time),
        });
    }

    requestConstageReport = (values) => {
        const projectId = this.context;
        this.setState({
            onGenerateReportLoading: true,
        })
        let type = 1;
        if (this.state.reportGenType === 'stage') {
            type = 2;
        } else if (this.state.reportGenType === 'final') {
            type = 3;
        }
        if (values['pre_report_index'] == null) {
            values['pre_report_index'] = '';
        }
        let params = {
            ...values,
            pre_report_index: values.pre_report_index || '',
            user_start_time: dayjs(values.user_start_time).valueOf(),
            user_end_time: dayjs(values.user_end_time).valueOf(),
        }
        generateReports(projectId, type, params)
            .then(res => {
                this.setState({
                    onGenerateReportLoading: false,
                    modalVisible: false,
                })
            }).finally(() => {
                this.setState({
                    onGenerateReportLoading: false,
                })
            })
    }



    render() {
        let { reports } = this.state;

        let calRemainingTime = (item: requestReport) => {
            let progress = item.generation_progress || 0;
            let time = 5 * 60 * ((100 - progress) / 100);
            if (time > 60) {
                return `剩余 ${(time / 60).toFixed(0)} 分钟`;
            } else {
                return `剩余 ${time.toFixed(0)} 秒`
            }

        }

        return (
            <div className="report-container">
                <div className="page-header">
                    <Row gutter={20}>
                        <Col span={8}>
                            <Button
                                size="large"
                                className="report-btn"
                            >监测方案</Button>
                        </Col>
                        <Col span={8}>
                            <Button
                                size="large"
                                className="report-btn"
                                onClick={() => this.setState({ modalVisible: true, reportGenType: 'stage' })}>阶段报告</Button>
                        </Col>
                        <Col span={8}>
                            <Button
                                size="large"
                                className="report-btn"
                                onClick={() => this.setState({ modalVisible: true, reportGenType: 'final' })}>总报告</Button>
                        </Col>
                    </Row>
                </div>
                {this.state.requestReports && this.state.requestReports.length > 0 && (
                    <div className="reports-generating">
                        <div className="title">
                            <Spin />
                            <span> 正在生成报告...</span>
                        </div>
                        {this.state.requestReports.map((item: requestReport, idx) => (
                            <div key={idx}>
                                <div className="request-report-item">
                                    <span>{item.report_index}</span>
                                    <span>{dayjs(item.user_start_time).format('YYYY-MM-DD HH:mm')}</span>
                                    <span>~</span>
                                    <span>{dayjs(item.user_end_time).format('YYYY-MM-DD HH:mm')}</span>
                                    <span>{item.generation_progress_status}</span>
                                    <span>{calRemainingTime(item)}</span>
                                </div>
                                <Progress percent={item.generation_progress} status="active" />
                            </div>
                        ))}
                    </div>
                )}

                <div className="content">
                    <div className="operations">
                        <Upload
                            showUploadList={false}
                            onChange={this.handleChange}
                            action={docUploadUrl}>
                            <Button><UploadOutlined />上传</Button>
                        </Upload>
                    </div>

                    <Table
                        dataSource={reports}
                        rowKey="id">
                        <Column title="名称" key="name" render={(record: report) => (
                            <span className="can-click" onClick={() => this.donwloadFile(record)}>{record.name}</span>
                        )} />
                        <Column title="时间" dataIndex="gmt_create" render={(record) => (
                            dayjs(record).format('YYYY-MM-DD HH:mm:ss')
                        )} />
                        <Column title="" key="operation" render={record => (
                            <Popconfirm
                                title="确认删除吗?"
                                onConfirm={() => this.onDelete(record)}
                                okText="是"
                                cancelText="否"
                            >
                                <Button type="link" danger icon={<DeleteOutlined />}></Button>
                            </Popconfirm>
                        )} />
                    </Table>
                </div>

                <Modal
                    visible={this.state.modalVisible}
                    destroyOnClose
                    closable={false}
                    footer={null}
                    width={800}
                    title={this.state.reportGenType === 'stage' ? '生成阶段报告' : '生成总报告'}>
                    <Form autoComplete="off" labelCol={{ span: 3 }} onFinish={this.requestConstageReport} ref={this.constageReportFormRef}>
                        {this.state.reportGenType === 'stage' && (
                            <Form.Item label="选择阶段" name="constage_id" rules={[{ required: true }]}>
                                <Select
                                    placeholder="施工阶段"
                                    // style={{ width: 400 }}
                                    onChange={this.onConstageSelectChange}
                                >
                                    {this.state.constages.map((item: constage, idx: number) => (
                                        <Option value={item.id} key={item.id}>{item.code} {item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item label="起始时间" name="user_start_time" rules={[{ required: true }]}>
                            <DatePicker showTime />
                        </Form.Item>
                        <Form.Item label="截止时间" name="user_end_time" rules={[{ required: true }]}>
                            <DatePicker showTime />
                        </Form.Item>
                        <Form.Item label="报告编号" name="report_index" rules={[{ required: true }]}>
                            <Input placeholder="如：JC-202002" />
                        </Form.Item>
                        {this.state.reportGenType === 'stage' && (
                            <Form.Item label="既往报告索引" name="pre_report_index" >
                                <Input placeholder="如：JC-202001" />
                            </Form.Item>
                        )}
                        <Form.Item label="结论" name="conclusions" rules={[{ required: true }]}>
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item label="建议" name="advices" rules={[{ required: true }]}>
                            <TextArea rows={4} />
                        </Form.Item>
                        <div style={{ textAlign: 'right' }}>
                            <Space size="middle">
                                <Button onClick={() => this.setState({ modalVisible: false })}>取消</Button>
                                <Button htmlType="submit" type="primary" loading={this.state.onGenerateReportLoading}>确定</Button>
                            </Space>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    visible={this.state.downloadLoading}
                    footer={null}
                    title={null}
                    closable={false}
                >
                    <Spin size="large" tip="正在下载报告...">
                        <div style={{ height: '100px' }}></div>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

export default withRoutePane(ReportPage, '报告');