import React, { Component } from 'react'
import { Button, List, message, Modal, Spin } from 'antd'
import IconFont from '../../config/iconfont'
import { report } from '../../interface/report'
import { ProjectContext } from '../../config/context'
import { queryReports, downloadReport } from '@/config/api/report'
import { RightOutlined } from '@ant-design/icons';
import './report-board.less';
import { withRouter } from 'react-router-dom'
import { Gprops } from '@/config/props'
import { downloadUrlPrefix } from '@/config/api/document'
interface IProps {
}

interface IState {
    reports: report[],
    downloadLoading: boolean,
}

class ReportBoard extends Component<Gprops, IState> {

    static contextType = ProjectContext;

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            downloadLoading: false,
        }
    }

    componentDidMount() {
        const projectId = this.context;
        queryReports(projectId)
            .then(res => {
                let data: [] = res.data || [];
                this.setState({
                    reports: data.slice(0, 4)
                })
            })
    }

    donwloadFile = (record: report) => {
        // const projectId = this.context;
        // this.setState({
        //     downloadLoading: true,
        // })
        // downloadReport(projectId, record.id)
        //     .then(res => {
        //         const blob = new Blob([res.data]);
        //         const link = document.createElement('a');
        //         let contentDisposition = res.headers['content-disposition'];
        //         console.log(contentDisposition)
        //         let filename = /filename="(.*)"/.exec(contentDisposition)[1] || '报告.docx';
        //         link.setAttribute('href', window.URL.createObjectURL(blob));
        //         link.setAttribute('download', filename);
        //         link.style.visibility = 'hidden';
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     }).catch(err => {
        //         console.log(err);
        //         message.error('下载失败');
        //     }).finally(() => {
        //         this.setState({
        //             downloadLoading: false,
        //         })
        //     })
        if (process.env.NODE_ENV === 'development') {
            const projectId = this.context;
            downloadReport(projectId, record.id).then(res => {
                if (res.status === 200) {
                    const url = URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = record.name;
                    link.click();
                    URL.revokeObjectURL(url);
                }
            })
        } else {
            const link = document.createElement('a');
            link.setAttribute('href', `/api${record.url}`);
            link.setAttribute('download', record.name);
            link.setAttribute('target', '_blank');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <div className="dashboard-card report">
                <div className="header">
                    <span className="title">文档</span>
                    <Button type="link" onClick={() => this.props.history.push(`/projects/${this.context}/reports`)}>详情<RightOutlined /></Button>
                </div>
                <div className="report-pane">
                    <List dataSource={this.state.reports} split={false} renderItem={item => (
                        <List.Item key={item.id} style={{ padding: '6px 0' }}>
                            <span className="report-item" onClick={() => this.donwloadFile(item)}>{item.name}</span>
                        </List.Item>
                    )} />
                </div>

                <Modal
                    visible={this.state.downloadLoading}
                    footer={null}
                    title={null}
                    closable={false}
                >
                    <Spin size="large" tip="正在下载报告...">
                        <div style={{ height: '100px' }}></div>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ReportBoard);