import React, { Component } from 'react'
import './header.less'
import { Button, Divider, Badge, Tooltip, message, Modal } from 'antd'
import IconFont from '../../config/iconfont'
import Setting from '../../components/header-rightcontent/setting'
import UserDropdown from '../../components/header-rightcontent/user-dropdown'
import { Gprops } from '@/config/props'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ProjectStore from '@/store/project-store'
import { PlayCircleFilled, PauseCircleFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { projectStart, projectStop } from '@/config/api/project'
import { ProjectContext } from '@/config/context'
import { queryWarningRemindCount, readWarningRemind } from '@/config/api/warning'
import UserStore from '@/store/user-store'
import { warning } from '@/interface/warning'
import { UserTypeEnum } from '@/routes/login/login'
import { downloadUrlPrefix } from '@/config/api/document'
const { confirm } = Modal;

interface IProps extends Gprops {
    projectStore?: ProjectStore
    userStore?: UserStore
}
interface IState {
    loading: boolean
    warningCount: number
    warningsUnread: warning[]
}
@inject('projectStore', 'userStore')
@observer
class MainHeader extends Component<IProps, IState> {
    static contextType = ProjectContext;
    userId: number = null;
    state = {
        loading: false,
        warningCount: 0,
        warningsUnread: [],
    }

    componentDidMount() {
        let userId = this.props.userStore._userInfo?.id;
        this.userId = userId;
        this.findWarningRemindCount();
    }

    componentDidUpdate() {
        let userId = this.props.userStore._userInfo?.id;
        if (userId !== this.userId) {
            this.userId = userId;
            this.findWarningRemindCount();
        }
    }

    findWarningRemindCount = () => {
        let userId = this.props.userStore._userInfo?.id;
        let projectId = this.context;
        if (!userId) return;
        queryWarningRemindCount(userId, projectId)
        .then(res => {
            let warnings: warning[] = res.data || [];
            this.setState({
                warningCount: warnings.length || 0,
                warningsUnread: warnings,
            })
        })
    }

    startProjectConfirm = () => {
        let userType = this.props.userStore._userInfo?.type;
        if (!!userType && userType <= UserTypeEnum.ENGINEER) {
            confirm({
                title: '确认开启监测评估吗?',
                icon: <ExclamationCircleOutlined />,
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: this.startProjectMonitoring,
            });
        } else {
            message.warning('权限不足');
        }
        
    }

    stopProjectConfirm = () => {
        let userType = this.props.userStore._userInfo?.type;
        if (!!userType && userType <= UserTypeEnum.ENGINEER) {
            confirm({
                title: '确认停止监测评估吗?',
                icon: <ExclamationCircleOutlined />,
                okText: '是',
                okType: 'danger',
                cancelText: '否',
                onOk: this.stopProjectMonitoring,
            });
        } else {
            message.warning('权限不足');
        }
    }

    startProjectMonitoring = () => {
        let projectId = this.context;
        this.setState({
            loading: true,
        })
        projectStart(projectId)
            .then(res => {
                if (res.data) {
                    this.props.projectStore.setProjectWorkStatus(true);
                } else {
                    throw new Error('开启失败');
                }
            }).catch(err => {
                if (!!err.data) {
                    message.error(err.data?.message);
                } else {
                    message.error(err);
                }
                console.log(err);
            }).finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    stopProjectMonitoring = () => {
        let projectId = this.context;
        this.setState({
            loading: true,
        })
        projectStop(projectId)
            .then(res => {
                if (res.data) {
                    this.props.projectStore.setProjectWorkStatus(false);
                } else {
                    throw new Error('停止失败');
                }
            }).catch(err => {
                if (!!err.data) {
                    message.error(err.data?.message);
                } else {
                    message.error(err);
                }
                console.log(err);
            }).finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    goToWarningPage = () => {
        let userId = this.userId;
        if(!userId) return;
        let wids = this.state.warningsUnread.map(item => item.id);
        readWarningRemind(userId, wids)
        .then(res => {
            this.setState({
                warningCount: 0,
            })
        })
        this.props.history.push(`${this.props.match.url}/warnings`);
    }

    downloadHelpFile = () => {
        const link = document.createElement('a');
        const filename = '同恩在线监测系统V2.0使用手册.pdf';
        let url = process.env.NODE_ENV === 'development' ? downloadUrlPrefix : document.domain;
        // link.setAttribute('href', `${downloadUrlPrefix}/api/static/docs/同恩在线监测系统V2.0使用手册.pdf`);
        link.setAttribute('href', `${url}/api/static/docs/同恩在线监测系统V2.0使用手册.pdf`);
        link.setAttribute('download', filename);
        link.setAttribute('target', '_blank');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        let projectWorking = this.props.projectStore._projectWorkStatus;
        return (
            <div className="main-header">
                <div className='title'>
                    <span>{this.props.projectStore._projectName}</span>
                </div>
                <div className="fill-remaining-space"></div>
                <div className="right">
                    {/* <Button type="link" size="large" className="action" icon={<PlaySquareOutlined />}>
                    </Button> */}
                    {!projectWorking && (
                        <Tooltip title="开始监测评估">
                            <div className="action project-start-btn" onClick={this.startProjectConfirm}>
                                <PlayCircleFilled spin={this.state.loading} />
                                <span className="content">开始</span>
                            </div>
                        </Tooltip>
                    )}
                    {projectWorking && (
                        <Tooltip title="停止监测评估">
                            <div className="action project-stop-btn" onClick={this.stopProjectConfirm}>
                                <PauseCircleFilled spin={this.state.loading} />
                                <span className="content">停止</span>
                            </div>
                        </Tooltip>
                    )}
                    <Setting />
                    <Divider type="vertical" style={{ height: '50%', borderLeft: '1px solid #d8d8d8' }} />
                    <Badge count={this.state.warningCount} style={{ transform: 'none' }} >
                        <Button
                            type="link"
                            size="large"
                            icon={<IconFont type="icon-yujing" />}
                            onClick={this.goToWarningPage}>报警</Button>
                    </Badge>
                    <Button
                        type="link"
                        size="large"
                        icon={<IconFont type="icon-rizhi" />}
                        className="action"
                        onClick={() => this.props.history.push(`${this.props.match.url}/logs`)}>日志</Button>
                    <Button
                        type="link"
                        size="large"
                        icon={<QuestionCircleOutlined />}
                        className="action"
                        onClick={this.downloadHelpFile}
                        >
                        帮助
                    </Button>
                    <UserDropdown />

                </div>
            </div>
        )
    }
}

export default withRouter(MainHeader);